<template>
	<div class="container-fluid page-login h-100">
		<BaseLoading v-if="isLoading || isAutenticacaoPorToken" />
		<div v-else class="row h-100">
			<div class="col-sm-12 h-100">
				<div class="row h-100">
					<!-- Conteudo da Lateral Esquerda -->
					<div class="col-xs-3 col-md-3 col-sm-12 bg-primary d-flex align-items-center">
						<div class="container">
							<div class="row">
								<div class="col-12">
									<h1 class="text-brand-linx" title="Linx">Linx</h1>
									<h2 class="text-brand-product text-uppercase text-truncate" title="Microvix"> Microvix </h2>
									<h3 class="text-uppercase text-white font-weight-bold" title="Venda Fácil">
										{{ nomeAplicacao }}
									</h3>
								</div>
								<p class="copyright"> &copy; Linx - Líder em Tecnologia de Gestão para o Varejo </p>
							</div>
						</div>
					</div>
					<!-- Conteudo Login - Direita com Inputs -->
					<div class="col">
						<div class="align-items-center">
							<div class="row">
								<!-- Logo -->
								<div class="col-12 div-logo">
									<picture class="center-align logotipo text-center" style="display: block">
										<source srcset="@/assets/Images/sm-logo-mob.png" media="(max-width: 768px)" />
										<source srcset="@/assets/Images/lg-logo.png" />
										<img src="@/assets/Images/lg-logo.png" alt="Logo Linx" />
									</picture>
								</div>

								<!-- INPUTS -->
								<div class="col-12">
									<form
										class="form-login"
										@submit.prevent="form_onSubmit"
										name="form_login"
										id="form_login"
										method="post"
									>
										<!-- Alert de Erro -->
										<div class="alert alert-danger" v-show="mensagemErro" id="div_aviso">
											<span id="msg">{{ mensagemErro }}</span>
											<a href="#" class="close" @click="atualizarMensagemErro(null)" title="close">x</a>
										</div>

										<!-- Box Nome Usuario -->
										<transition name="slide-fade">
											<div v-if="exibeUsuarioESenha">
												<div class="form-group">
													<label for="f_login">Usuário</label>
													<input
														class="form-control"
														type="text"
														id="f_login"
														name="f_login"
														maxlength="100"
														placeholder="Usuário"
														autocomplete="off"
														autofocus
														required
														v-model="login"
														:disabled="autenticacaoUsuarioESenhaRealizada || isFetching"
													/>
												</div>
												<!-- Box Senha Usuario -->
												<div class="form-group">
													<label for="f_senha">Senha</label>
													<input
														class="form-control"
														type="password"
														id="f_senha"
														name="f_senha"
														placeholder="Senha"
														required
														v-model="senha"
														:disabled="autenticacaoUsuarioESenhaRealizada || isFetching"
													/>
												</div>

												<button
													type="submit"
													class="btn btn-secondary btn-block"
													id="lmxta-login-btn-autenticar"
													:disabled="isFetching"
												>
													Autenticar
													<span style="float: right" v-if="isFetching">
														<i class="fas fa-sync fa-spin"></i>
													</span>
												</button>
											</div>
										</transition>

										<!-- Box Empresa -->
										<transition name="slide-fade">
											<div v-if="exibeEmpresa">
												<div class="form-group">
													<label>Empresa</label>

													<multiselect
														ref="multiselectEmpresa"
														v-model="empresaSelecionada"
														track-by="IdPortalEmpresa"
														label="EmpresaLabel"
														placeholder="Selecione uma empresa"
														:options="portaisParaSelecao"
														group-values="Empresas"
														group-label="PortalLabel"
														selectLabel="Selecionar"
														deselectLabel="Remover"
														selectedLabel="Selecionada"
														:disabled="isFetching"
														open-direction="bottom"
														:max-height="300"
														id="lmxta-login-input-empresa"
														name="lmxta-login-input-empresa"
													>
														<span slot="noResult">Nenhuma empresa encontrada.</span>
													</multiselect>
												</div>

												<button
													type="submit"
													class="btn btn-secondary btn-block"
													id="lmxta-login-btn-selecionar"
													:disabled="isFetching"
												>
													Selecionar
													<span style="float: right" v-if="isFetching">
														<i class="fas fa-sync fa-spin"></i>
													</span>
												</button>
												<input
													type="button"
													class="btn btn-default btn-block"
													id="lmxta-login-btn-voltar"
													:disabled="isFetching"
													@click="voltarParaLoginUsuario"
													value="Voltar"
												/>
											</div>
										</transition>

										<transition name="slide-fade">
											<div v-if="exibeRotinas">
												<div class="form-group">
													<label>Rotinas</label>

													<multiselect
														ref="multiselectRotina"
														v-model="rotinaSelecionada"
														track-by="name"
														label="label"
														placeholder="Selecione uma rotina"
														:options="rotinasParaSelecao"
														selectLabel="Selecionar"
														deselectLabel="Remover"
														selectedLabel="Selecionada"
														open-direction="bottom"
														:max-height="300"
														id="lmxta-login-input-rotina"
														name="lmxta-login-input-rotina"
													>
														<span slot="noResult">Nenhuma rotina encontrada.</span>
													</multiselect>
												</div>

												<button
													type="submit"
													class="btn btn-secondary btn-block"
													id="lmxta-login-btn-selecionar-rotina"
												>
													Selecionar
												</button>

												<input
													type="button"
													class="btn btn-default btn-block"
													id="lmxta-login-btn-voltar"
													@click="voltarParaLoginUsuario"
													value="Voltar"
												/>
											</div>
										</transition>

										<div class="row mb-2">
											<div class="col">
												<div
													class="custom-control custom-checkbox custom-control-position"
													style="display: none"
												>
													<input type="checkbox" class="custom-control-input" id="f_manter_conectado" />
													<label
														class="custom-control-label text-uppercase control-label"
														for="f_manter_conectado"
													>
														Manter Conectado
													</label>
												</div>
											</div>
										</div>

										<!-- Redes Sociais -->
										<div class="row center-align text-center social-media">
											<div class="col-sm-12 mb-1">
												<small>Siga a Linx nas Redes Sociais</small>
											</div>
											<div class="col-sm-12 mb-2 d-flex justify-content-center">
												<a target="_blank" href="http://www.facebook.com/linxretail" class="fa-login">
													<i class="fab fa-facebook-f"></i>
												</a>
												<a
													target="_blank"
													href="http://www.linkedin.com/company/linxretail"
													class="fa-login"
												>
													<i class="fab fa-linkedin-in"></i>
												</a>
												<a target="_blank" href="http://www.twitter.com/linxretail" class="fa-login">
													<i class="fab fa-twitter"></i>
												</a>
												<a
													target="_blank"
													href="https://www.youtube.com/playlist?list=PLxzoU_Ud-MeJK3Du_lT6XqodzWkpnj8Ra"
													class="fa-login"
												>
													<i class="fab fa-youtube"></i>
												</a>
												<a target="_blank" href="https://www.instagram.com/linxretail/" class="fa-login">
													<i class="fab fa-instagram"></i>
												</a>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapWaitingActions } from 'vue-wait';
	import { mapState, mapGetters } from 'vuex';
	import { Multiselect } from 'vue-multiselect';

	import routes from '@/router/routes';
	import { getRoute } from '@/router/utils';

	import swal from '@/common/alerts';

	export default {
		name: 'NavegacaoLogin',

		components: {
			Multiselect,
		},

		data() {
			return {
				rotinasParaSelecao: [
					{ label: 'Importação de produtos', name: 'ProdutosImportacao' },
					{ label: 'Balanço', name: 'PainelBalancos' },
					{ label: 'Devolução', name: 'DevolucaoEntrada' },
				],
				login: null,
				senha: null,
				mensagemErro: null,
				isFetching: false,
				empresaSelecionada: null,
				rotinaSelecionada: null,
				exibeUsuarioESenha: true,
				exibeEmpresa: false,
				exibeRotinas: false,
			};
		},

		computed: {
			...mapState('autenticacao', {
				isAutenticado: (state) => state.autenticado,
			}),
			...mapGetters('autenticacao', {
				autenticacaoUsuarioESenhaRealizada: 'autenticacaoUsuarioESenhaRealizada',
				portaisParaSelecao: 'portaisParaSelecao',
				nomeAplicacao: 'nomeAplicacao',
				isLoading: 'isLoading',
			}),
			isAutenticacaoPorToken() {
				const { authToken, appName, rotina } = this.$route.query;
				return authToken && appName && rotina ? true : false;
			},
			isAutenticacaoPorTokenExecucaoBalancoCelular() {
				const { idBalanco, idSessao } = this.$route.query;
				return idBalanco && idSessao ? true : false;
			},
			isAutenticacaoPorTokenConferenciaOnlineRecebimento() {
				const { idRecebimento } = this.$route.query;
				return idRecebimento ? true : false;
			},
		},
		methods: {
			...mapWaitingActions('autenticacao', {
				loginPorToken: 'loginPorToken autenticacao',
				executarLogin: 'executarLogin autenticacao',
			}),

			exibirUsuarioESenha() {
				this.mensagemErro = '';
				this.exibeEmpresa = false;
				this.exibeRotinas = false;
				setTimeout(() => {
					this.exibeUsuarioESenha = true;
				}, 300);
			},

			exibirEmpresa() {
				this.mensagemErro = '';
				this.exibeUsuarioESenha = false;
				this.exibeRotinas = false;
				setTimeout(() => {
					this.exibeEmpresa = true;
				}, 300);
			},

			exibirRotinas() {
				this.mensagemErro = '';
				this.exibeUsuarioESenha = false;
				this.exibeEmpresa = false;
				setTimeout(() => {
					this.exibeRotinas = true;
				}, 300);
			},

			atualizarMensagemErro: function (mensagem) {
				this.mensagemErro = mensagem;
			},

			form_onSubmit() {
				if (this.isAutenticado) return this.selecionarRotina();

				if (!this.autenticacaoUsuarioESenhaRealizada) return this.autenticar();

				if (this.autenticacaoUsuarioESenhaRealizada) return this.selecionarEmpresa();
			},

			autenticar() {
				if (!this.login || !this.senha) {
					this.atualizarMensagemErro('Informe o Usuário/Senha para continuar.');
					return false;
				}

				this.atualizarMensagemErro(null);

				const payload = {
					login: this.login,
					senha: this.senha,
				};

				this.isFetching = true;
				this.executarLogin(payload)
					.then(() => {
						if (this.$store.state.autenticacao.portaisUsuario.length === 1) {
							const portal = this.$store.state.autenticacao.portaisUsuario[0];
							if (portal.Empresas.length === 1) {
								this.autenticarComEmpresaEAvancar(portal.Id, portal.Empresas[0].Id);
								return;
							}
						}

						this.isFetching = false;
						this.exibirEmpresa();
					})
					.catch(() => {
						this.atualizarMensagemErro('Usuário ou senha incorretos.');
						this.isFetching = false;
					});
			},

			selecionarEmpresa() {
				if (!this.autenticacaoUsuarioESenhaRealizada) {
					return false;
				}

				if (!this.empresaSelecionada) {
					this.atualizarMensagemErro('Selecione a empresa para continuar.');
					return false;
				}

				const dadosEmpresaSelecionada = this.empresaSelecionada.IdPortalEmpresa.split('-');
				this.autenticarComEmpresaEAvancar(dadosEmpresaSelecionada[1], dadosEmpresaSelecionada[0]);
			},

			selecionarRotina() {
				if (!this.rotinaSelecionada) return this.atualizarMensagemErro('Selecione a rotina para continuar.');
				this.redirecionarParaProximaPagina(this.rotinaSelecionada.name);
			},

			voltarParaLoginUsuario() {
				this.senha = '';
				this.$store.dispatch('autenticacao/handleLogout');
				this.exibirUsuarioESenha();
				this.empresaSelecionada = null;
			},

			autenticarComEmpresaEAvancar: function (idPortal, idEmpresa) {
				this.atualizarMensagemErro(null);
				this.isFetching = true;
				this.$store
					.dispatch('autenticacao/selecionarEmpresa', {
						IdPortal: idPortal,
						IdEmpresa: idEmpresa,
					})
					.then((resp) => {
						this.isFetching = false;
						this.exibirRotinas();
					})
					.catch((err) => {
						this.atualizarMensagemErro('Não foi possível selecionar a empresa.');
						this.isFetching = false;
					});
			},

			async handleLoginPorToken() {
				const { authToken, appName, rotina, idConfigAmbiente } = this.$route.query;

				const payload = {
					token: authToken,
					nomeAplicacaoOrigem: appName,
					idConfigAmbiente: idConfigAmbiente ?? 0,
				};

				// if (this.isAutenticado) return this.redirecionarParaProximaPagina(rotina);

				try {
					await this.loginPorToken(payload);

					if (!this.isAutenticado) {
						swal.exibirToastAlerta('Não foi possível realizar a Autenticação integrada.');
						return this.redirecionarParaProximaPagina('NavegacaoLogin');
					}

					return this.redirecionarParaProximaPagina(rotina);
				} catch (error) {
					swal.exibirToastAlerta('Ocorreu algo inesperado ao realizar a Autenticação integrada.');
					return this.redirecionarParaProximaPagina('NavegacaoLogin');
				}
			},

			async handleLoginPorTokenExecucaoBalancoCelular() {
				const { token } = this.$route.query;
				const rotina = 'ExecucaoSecao';

				const payload = {
					token,
					nomeAplicacaoOrigem: 'LinxMicrovixERP',
				};

				if (this.isAutenticado) return this.redirecionarParaProximaPagina(rotina, this.$route.query);

				try {
					await this.loginPorToken(payload);

					if (!this.isAutenticado) {
						swal.exibirToastAlerta('Não foi possível realizar a Autenticação integrada.');
						return this.redirecionarParaProximaPagina('NavegacaoLogin');
					}

					return this.redirecionarParaProximaPagina(rotina, this.$route.query);
				} catch (error) {
					swal.exibirToastAlerta('Ocorreu algo inesperado ao realizar a Autenticação integrada.');
					return this.redirecionarParaProximaPagina('NavegacaoLogin');
				}
			},

			async handleLoginPorTokenConferenciaOnlineRecebimento() {
				const { token } = this.$route.query;
				const rotina = 'ConferenciaOnlineRecebimento';

				const payload = {
					token,
					nomeAplicacaoOrigem: 'LinxMicrovixERP',
				};

				if (this.isAutenticado) return this.redirecionarParaProximaPagina(rotina, this.$route.query);

				try {
					await this.loginPorToken(payload);

					if (!this.isAutenticado) {
						swal.exibirToastAlerta('Não foi possível realizar a Autenticação integrada.');
						return this.redirecionarParaProximaPagina('NavegacaoLogin');
					}

					return this.redirecionarParaProximaPagina(rotina, this.$route.query);
				} catch (error) {
					swal.exibirToastAlerta('Ocorreu algo inesperado ao realizar a Autenticação integrada.');
					return this.redirecionarParaProximaPagina('NavegacaoLogin');
				}
			},

			redirecionarParaProximaPagina(nomeRotaParaRedirecionar, queryParams = {}) {
				if (nomeRotaParaRedirecionar === 'ImportacaoProdutos') nomeRotaParaRedirecionar = 'ProdutosImportacao';
				const rota = getRoute(routes, nomeRotaParaRedirecionar);
				this.$router.push({ name: rota.name, query: queryParams });
			},

			async init() {
				if (this.isAutenticacaoPorTokenExecucaoBalancoCelular) {
					await this.handleLoginPorTokenExecucaoBalancoCelular();
					return;
				}
				if (this.isAutenticacaoPorTokenConferenciaOnlineRecebimento) {
					await this.handleLoginPorTokenConferenciaOnlineRecebimento();
					return;
				}

				if (this.isAutenticacaoPorToken) {
					await this.handleLoginPorToken();
					return;
				}

				if (this.isAutenticado) {
					this.exibirRotinas();
					return;
				}

				if (this.autenticacaoUsuarioESenhaRealizada) {
					this.login = this.$store.state.autenticacao.usuario.Login;
					this.senha = '******';
					this.exibirEmpresa();
					return;
				}

				if (!this.isAutenticado) {
					this.exibirUsuarioESenha();
					return;
				}
			},
		},

		async mounted() {
			await this.init();
		},
	};
</script>

<style scoped>
	@media (max-height: 300px) {
		.multiselect--active {
			position: fixed !important;
			top: 0 !important;
			left: 0 !important;
		}
	}

	.slide-fade-enter-active {
		transition: all 0.3s ease;
	}

	.slide-fade-leave-active {
		transition: all 0.3s ease;
	}

	.slide-fade-enter,
	.slide-fade-leave-to {
		transform: translateX(10px);
		opacity: 0;
	}

	.page-login {
		background-color: #fff;
	}

	.page-login picture {
		margin-bottom: 50px;
	}

	.page-login .copyright {
		color: #fff;
		font-size: 0.8rem;
		position: absolute;
		bottom: 0px;
		left: 8px;
	}

	.page-login .link-support-web {
		position: absolute;
		right: 20px;
		bottom: 0;
		font-size: 2rem;
	}

	.link-support-mobile {
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 0.8rem;
		display: none;
	}

	.colum-height {
		height: 100vh;
	}

	.text-brand-linx {
		color: #ffb200;
		font-size: 2.625rem;
		font-weight: 300;
		margin-bottom: 0;
	}

	.text-brand-product {
		color: #fff;
		font-size: 3rem;
		font-weight: 300;
	}

	.page-login picture,
	.form-login {
		width: 43%;
		margin-left: 150px;
		padding-top: 30px;
		margin-bottom: 0px;
	}

	.form-login .control-label {
		font-size: 0.875rem;
		cursor: pointer;
		margin-top: 1.7rem;
	}

	.form-recover-password h1 {
		font-size: 1.875rem;
	}

	/* 30px */

	/* Portrait */
	@media only screen and (max-width: 414px) {
		img {
			max-width: 50%;
			max-height: 50%;
		}

		.page-login picture,
		.form-login {
			width: auto;
		}

		.page-login .link-support-web {
			display: none;
		}

		.link-support-mobile {
			display: block;
		}

		.form-login .control-label {
			font-size: 0.8rem;
			cursor: pointer;
		}

		.text-brand-linx {
			font-size: 1.25rem;
		}

		.text-brand-product {
			font-size: 1rem;
		}

		a.btn-link {
			margin-top: 1.7rem;
		}
	}

	@media only screen and (max-width: 767px) {
		.page-login picture,
		.form-login {
			margin-left: auto;
			margin-right: auto;
		}

		.page-login .copyright {
			display: none;
		}

		.link-support-web {
			display: none;
		}

		.link-support-mobile {
			display: block;
		}

		.colum-height-a {
			height: 10vh;
		}

		.colum-height-b {
			height: 90vh;
		}
	}

	@media only screen and (min-width: 768px) and (max-width: 1023px) {
		.text-brand-linx {
			font-size: 2.5rem;
		}

		.text-brand-product {
			font-size: 2rem;
		}
	}

	@media only screen and (min-width: 768px) and (max-width: 1023px) {
		.social-media {
			margin-top: 40px;
		}
	}

	/* Landscape */

	@media only screen and (orientation: landscape) and (max-width: 823px) {
		.div-logo {
			display: none;
		}

		.social-media {
			display: none;
		}

		.link-support-web {
			display: none;
		}

		.link-support-mobile {
			display: block;
		}

		.alert {
			margin-top: 1.8rem;
		}
	}

	@media only screen and (orientation: landscape) and (max-width: 736px) {
		.colum-height-a {
			height: 10%;
			margin-bottom: 35px;
		}

		.form-group,
		.alert {
			width: 350px !important;
		}

		.btn {
			width: 350px !important;
		}

		.text-brand-linx {
			font-size: 1.75rem;
		}

		.text-brand-product {
			font-size: 1.75rem;
		}

		.alert {
			margin-top: 1.8rem;
		}
	}

	.fa-login svg {
		display: block;
		float: left;
		padding: 8px;
		font-size: 20px;
		width: 35px !important;
		height: 35px !important;
		text-align: center;
		text-decoration: none;
		border-radius: 50%;
		background: #ebebeb;
		color: black;
		margin-right: 5px;
	}

	.fa-facebook-f:hover {
		color: white;
		background-color: #3b5998;
		text-decoration: none;
		-webkit-transition: background-color 1000ms linear;
		-ms-transition: background-color 1000ms linear;
		transition: background-color 1000ms linear;
		-webkit-transition: color 300ms linear;
		-ms-transition: color 300ms linear;
		transition: color 300ms linear;
	}

	.fa-linkedin-in:hover {
		color: white;
		background-color: #0077b5;
		text-decoration: none;
		-webkit-transition: background-color 1000ms linear;
		-ms-transition: background-color 1000ms linear;
		transition: background-color 1000ms linear;
		-webkit-transition: color 300ms linear;
		-ms-transition: color 300ms linear;
		transition: color 300ms linear;
	}

	.fa-twitter:hover {
		color: white;
		background-color: #1da1f2;
		text-decoration: none;
		-webkit-transition: background-color 1000ms linear;
		-ms-transition: background-color 1000ms linear;
		transition: background-color 1000ms linear;
		-webkit-transition: color 300ms linear;
		-ms-transition: color 300ms linear;
		transition: color 300ms linear;
	}

	.fa-youtube:hover {
		color: white;
		background-color: #ff0000;
		text-decoration: none;
		-webkit-transition: background-color 1000ms linear;
		-ms-transition: background-color 1000ms linear;
		transition: background-color 1000ms linear;
		-webkit-transition: color 300ms linear;
		-ms-transition: color 300ms linear;
		transition: color 300ms linear;
	}

	.fa-instagram:hover {
		background-image: linear-gradient(
			to right top,
			#ffb200,
			#ff8230,
			#ff5352,
			#e22d6e,
			#b42185,
			#952086,
			#732184,
			#4f227e,
			#461971,
			#3d1064,
			#350757,
			#ffb200
		);
		color: white;
		text-decoration: none;
		-webkit-transition: background-image 1000ms linear;
		-ms-transition: background-image 1000ms linear;
		transition: background-image 1000ms linear;
		-webkit-transition: color 300ms linear;
		-ms-transition: color 300ms linear;
		transition: color 300ms linear;
	}
</style>
